<template>
    <div class="page flex-col">
        <header>
            <div class="bgImg">
                <img src="./assets/img/header_logo.png" alt="">
            </div>
            <div class="nearbyStore">
                <van-nav-bar
                        title="门店信息"
                        left-text=""
                        left-arrow
                        @click-left="onClickLeft"
                />
                <div class="nearbyStoreInfo">
                    <div class="storeJieshao">
                        <img :src="`${shopInfo.imagePathUrl}`" alt=""/>
                        <div class="detail">
                            <h3 style="font-size: 1.06rem;">
                                {{shopInfo.shopName}}
                            </h3>
                            <div class="rate" style="margin-top: 0.3rem">
                                <img src="./assets/img/fullStar.png" alt="">
                                <img src="./assets/img/fullStar.png" alt="">
                                <img src="./assets/img/fullStar.png" alt="">
                                <img src="./assets/img/halfStar.png" alt="">
                            </div>
                            <div class="businessHours">
                                <span>营业</span>
                                <span>{{shopInfo.shopHours}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="storeAddress">
                        {{shopInfo.shopAddress}}
                    </div>
                    <div class="board" v-if="!isTable">
                        <span>{{tableCode}}号桌</span>
                    </div>
                </div>
            </div>
        </header>
        <section>
            <!--
            <div class="nearbyBar">
              <ul>
                   <router-link :to="{ name: 'dishes', query: { shopId: shopId } }" >
                       <li @click="handleDishes" :class="isActive?'active':''">
                           点菜
                       </li>
                   </router-link>
                   <router-link :to="{ name: 'shangjia', query: { shopId: shopId } }">
                       <li @click="handleDishes" :class="isActive?'active':''">
                           商家
                       </li>
                   </router-link>
               </ul>
           </div>
            -->
            <br>
            <childComponent @valuesent="handleValueFromChild"></childComponent>

            <div class="showDishes">
                <router-view></router-view>
            </div>

        </section>

        <footer>
            <div class="buy">
                <span class="text_30">¥</span>
                <span class="text_31">{{orderParams.orderPrice}}</span>
            </div>
            <span class="text_32">已选{{orderParams.orderNum}}件</span>
            <span class="submitOrder" @click="addOrderInfo">提交订单</span>
        </footer>

        <div v-if="openCanzhuo" class="box_10s flex-col">
            <div class="group_17s flex-col">
                <span class="text_29s" v-show="isTable">&nbsp;&nbsp;&nbsp;请输入餐桌号</span>
                <!--         <div class="box_11s flex-row justify-between">
                              <div class="text-wrapper_12s flex-col"><span class="text_30s">1</span></div>
                              <div class="text-wrapper_13s flex-col"><span class="text_31s">2</span></div>
                              <div class="text-wrapper_14s flex-col"><span class="text_32s">3</span></div>
                              <div class="text-wrapper_15s flex-col"><span class="text_33s">4</span></div>
                         </div>
                         <div class="box_12s flex-row justify-between">
                              <div class="text-wrapper_16s flex-col"><span class="text_34s">5</span></div>
                              <div class="text-wrapper_17s flex-col"><span class="text_35s">6</span></div>
                              <div class="text-wrapper_18s flex-col"><span class="text_36s">7</span></div>
                              <div class="text-wrapper_19s flex-col"><span class="text_37s">8</span></div>
                         </div>
                         <div class="box_13s flex-row justify-between">
                              <div class="text-wrapper_20s flex-col"><span class="text_38s">9</span></div>
                              <div class="text-wrapper_21s flex-col"><span class="text_39s">10</span></div>
                              <div class="text-wrapper_22s flex-col"><span class="text_40s">11</span></div>
                              <div class="text-wrapper_23s flex-col"><span class="text_41s">12</span></div>
                         </div>-->
                <div class="text-wrapper_24s flex-col" v-show="isTable">
                    <input type="text" class="text_42s" v-model="tableCode" placeholder="请输入桌号"/>
                </div>
                <!--<span class="text_29s">&nbsp;&nbsp;&nbsp;请输入就餐人数</span>-->
                <div class="text-wrapper_24s flex-col">
                    <input type="number" class="text_42s" v-model="peopleNumber" placeholder="请输入就餐人数"/>
                </div>
                <div class="text-wrapper_24s flex-col" v-show="isPhone">
                    <input type="text" class="text_42s" v-model="peoplePhone" placeholder="请输入手机号码"/>
                </div>

                <div class="text-wrapper_25s flex-col" @click="saveOrderInfo()">
                    <span class="text_43s">确认</span>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
    import {queryUserByMemberId} from "@/api/user";
    import {queryShopInfo, addOrderInfo, getOpenId} from "@/api/order";
    import axios from 'axios';
    import childComponent from "@/views/enterMall/dishes.vue";

    export default {
        components: {
            childComponent
        },
        data() {
            return {
                isTable: false,
                isPhone: false,
                openCanzhuo: false,
                shopId: '',
                isActive: 0,
                baseURL: axios.defaults.baseURL,
                shopInfo: {imagePathUrl: ''},
                orderParams: {},
                tableCode: '',
                orderCode: '',
                peopleNumber: '',
                peoplePhone: '',
                userMember: {},
                openIdParams: {},
                userInfo: {},
                userVo: {}
            };
        },
        created() {
           /* const myObject = {
                openId: "opzKR6RklF4QjQeT1MeK6sBhAwyU",
                account: "opzKR6RklF4QjQeT1MeK6sBhAwyU",
                memberId: 361
            };
            localStorage.setItem('userInfoDs', JSON.stringify(myObject))*/
            const user = localStorage.getItem('userInfoDs')
            if(user){
                this.userInfo = JSON.parse(user);
                queryUserByMemberId(JSON.parse(user).memberId).then(response => {
                    if (response.code == 200) {
                        localStorage.setItem('userInfoDs', JSON.stringify(response.data))
                    }
                    //alert("缓存不为空"+response.data.memberId)
                });

            }
            //1、判断账号是否登录，使用该账号进行点餐
            if (this.isObjectEmpty(this.userInfo)) {
                //alert("缓存为空，开始授权")
                //2、未登录开始微信授权
                let code = this.getUrlCode('code');
                if(code){ //拿到code， code传递给后台接口换取opend
                    //alert("获取到得code：" + code)
                    this.openIdParams.isDc = 'Y';
                    this.openIdParams.code = code;
                    getOpenId(this.openIdParams).then(res => {
                        if (res.code == 200) {
                            localStorage.setItem('userInfoDs', JSON.stringify(res.data))
                            localStorage.setItem('userOpenIdDs', res.data.openId)
                        }else{
                            this.$model({
                                show: true,
                                title: "提示",
                                content: "授权失败，请退出从微信重新进入！",
                                confirmButton: false,
                                cancelButton: true
                            });
                            return false
                        }
                    });
                }else{
                    this.getWeChatCode();
                }

            }
            this.shopId = this.$route.query.shopId;
            this.tableCode = this.$route.query.tableNumber;
            this.orderCode = this.$route.query.orderCode;
            if (!this.tableCode) {
                this.isTable = true
            }
        },
        mounted() {
            /* let pathName = window.location.pathname
             if (pathName == '/enterMall') {
                 this.$router.push('/enterMall/dishes')
             }*/
            //console.log('location', window.location.pathname);
            queryShopInfo(this.shopId).then(response => {
                if (response.code == 200) {
                    this.shopInfo = response.data;
                    this.shopInfo.imagePathUrl = this.baseURL + response.data.imagePath;
                }
            });
        },
        methods: {
            // 获取url中的code方法
            getUrlCode(name) {
                return (
                    decodeURIComponent(
                        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                            location.href
                        ) || ["", ""])[1].replace(/\+/g, "%20")
                    ) || null
                );
            },
            getWeChatCode() {
                //授权后重定向的回调链接地址(即需要调跳转的H5页面)
                let urlNow = encodeURIComponent(window.location.href);
                // 通过微信官方接口获取code之后，会重新刷新设置的回调地址【redirect_uri】
                let timeStamp = new Date().getTime();
                let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?' +
                    'appid=wx2dcff060cfde0dc4&response_type=code&scope=snsapi_base' +
                    '&redirect_uri=' + urlNow + '&state=' + timeStamp + '&connect_redirect=1#wechat_redirect';
                window.location.href = url
            },
            handleValueFromChild(data) {
                this.orderParams = data
            },
            addOrderInfo() {
                const user = localStorage.getItem('userInfoDs')
                this.userVo = JSON.parse(user)

                if(!this.orderCode && this.userVo.type == 0 && this.userVo.account.length>20){
                    this.isPhone = true
                }
                if (!this.tableCode) {
                    this.$model({
                        show: true,
                        title: "提示",
                        content: "请扫描餐桌上的二维码进行点餐",
                        confirmButton: false,
                        cancelButton: true
                    });
                    return false
                }
                if (this.orderParams.orderPrice > 0) {
                    this.openCanzhuo = true
                } else {
                    this.$model({
                        show: true,
                        title: "提示",
                        content: "请选择菜品",
                        confirmButton: false,
                        cancelButton: true
                    });
                    return false
                }
            },
            saveOrderInfo() {
                if(!this.orderCode && this.userVo.type == '0' && this.userVo.account.length>20){
                    const mobileReg = '^1\\d{10}$'
                    if (!new RegExp(mobileReg).test(this.peoplePhone)) {
                        this.$model({
                            show: true,
                            title: "提示",
                            content: "手机号码格式不正确",
                            confirmButton: false,
                            cancelButton: true
                        });
                        return false
                    }
                }else{
                    this.peoplePhone = this.userVo.account
                }

                this.orderParams.tableCode = this.tableCode
                this.orderParams.orderCode = this.orderCode
                if (!this.orderParams.tableCode) {
                    this.$model({
                        show: true,
                        title: "提示",
                        content: "请扫餐桌上的二维码进行点餐",
                        confirmButton: false,
                        cancelButton: true
                    });
                    return false
                }
                this.orderParams.peopleNumber = Number(this.peopleNumber)
                this.orderParams.peoplePhone = this.peoplePhone
                if (this.orderParams.peopleNumber > 0) {
                    if (this.isObjectEmpty(this.userVo)) {
                        this.$model({
                            show: true,
                            title: "提示",
                            content: "微信授权失败，请重试！",
                            confirmButton: false,
                            cancelButton: true
                        });
                        return false
                    }
                    this.orderParams.memberAccount = this.userVo.account
                    this.orderParams.memberId = this.userVo.memberId
                    addOrderInfo(this.orderParams).then(response => {
                        if (response.code == 200) {
                            this.$model({
                                show: true,
                                title: "提示",
                                content: response.msg,
                                confirmButton: false,
                                cancelButton: true
                            });
                            this.$router.push({name: 'orderInfo', query: {orderCode: response.data.orderCode}});
                        } else {
                            if (response.msg.indexOf("已点") != -1) {
                                this.$model({
                                    show: true,
                                    title: "提示",
                                    content: response.msg,
                                    confirmButton: true,
                                    cancelButton: true,
                                    confirmText: '确认',
                                    cancelText: '取消',
                                    cancelCallBack: () => {
                                    },
                                    confirmCallBack: () => {
                                        this.saveOrderInfo()
                                    }
                                });
                            } else {
                                this.$model({
                                    show: true,
                                    title: "提示",
                                    content: response.msg,
                                    confirmButton: false,
                                    cancelButton: true
                                });
                            }
                        }
                    });

                } else {
                    this.$model({
                        show: true,
                        title: "提示",
                        content: "请输入就餐人数",
                        confirmButton: false,
                        cancelButton: true
                    });
                    return false
                }
            },
            // 返回
            onClickLeft() {
                //this.$router.go(-1)
                this.$router.push('/home')
            },
            handleDishes() {
                console.log('666');
            },
            isObjectEmpty(obj) {
                return Object.keys(obj).length==0
            },
        }
    };
</script>
<style scoped lang="css">
    * {
        padding: 0;
        margin: 0;
    }

    html,
    body {
        font-size: 37.5px;
        height: 100%;
    }

    .page {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    footer {
        height: 4.5rem;
        padding: 0 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .buy span {
        color: #fb743a;
        font-size: 1.33rem;
    }

    .submitOrder {
        background-color: #fb743a;
        color: #fff;
        font-size: 1.22rem;
        padding: 0.8rem 1.46rem;
        border-radius: 2.66rem;
    }

    .flex-row {
        display: flex;
    }

    header {
        height: 14.2rem;
        position: relative;
        position: relative;
        background-color: #fb743a;
    }

    section {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .bgImg img {
        width: 100%;
        height: 11.2rem;
    }

    .nearbyStore {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    ::v-deep .van-nav-bar {
        background-color: transparent;
    }

    .nearbyStoreInfo {
      /*  margin: 0 1.33rem;*/
        padding: 1.33rem 1.06rem 1rem;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 20px;
        margin-bottom: 1.04rem;
    }

    .storeJieshao {
        display: flex;
    }

    .storeJieshao > img {
        width: 6.93rem;
        height: 4.8rem;
        margin-right: 0.8rem;
    }

    .detail {
        display: flex;
        flex-direction: column;
    }

    .detail h3 {
        height: 1.38rem;
        line-height: 1.38rem;
        font-size: 1.06rem;
        color: #333;
    }

    .detail .rate img {
        width: 1.12rem;
        height: 1.12rem;
    }

    .businessHours {
        margin-top: 0.3rem;
    }

    .board {
        position: absolute;
        right: 2.48rem;
        bottom: 5.22rem;
        font-size: 1.28rem;
        color: #333;
        font-weight: bold;
    }

    .board span {
        font-style: italic;
    }

    .businessHours span:nth-child(1) {
        display: inline-block;
        padding: 0.13rem 0.32rem;
        /*font-size: 0.69rem;*/
        color: #fff;
        background-color: #fc7a3d;
        border-radius: 3px;
    }

    .businessHours span:nth-child(2) {
        /*font-size: 0.69rem;*/
        color: #999;
        margin-left: 0.53rem;
    }

    .storeAddress {
        margin-top: 0.8rem;
        /*font-size: 0.8rem;*/
        color: #999;;
    }

    .nearbyBar {
        padding-left: 1.06rem;
        height: 4.3rem;
    }

    .nearbyBar ul {
        display: flex;
    }

    .nearbyBar li {
        width: 3.73rem;
        height: 4rem;
        line-height: 4rem;
        color: #666;
        font-size: 1.06rem;
        text-align: center;
    }

    .showDishes {
        flex: 1;
        overflow: hidden;
    }

    .router-link-active {
        color: #fb743a;
        font-size: 1.22rem;
        border-bottom: 0.26rem solid #fb743a;
        /* border-radius: 5px 5px; */
        box-sizing: border-box;
        text-align: center;
    }

    .router-link-active li {
        color: #fb743a;
        font-size: 1.22rem;
    }

    ::v-deep .van-nav-bar .van-icon {
        color: #fff;
    }

    ::v-deep .van-nav-bar__title {
        color: #fff;
    }

    .text_30 {
        width: 5.6rem;
        height: 1.28rem;
        overflow-wrap: break-word;
        color: rgba(216, 30, 6, 1);
        font-size: 1.28rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.28rem;
    }

    .text_31 {
        width: 5.6rem;
        height: 1.28rem;
        overflow-wrap: break-word;
        color: rgba(216, 30, 6, 1);
        font-size: 1.653rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.654rem;
    }

    .text_32 {
        width: 3.734rem;
        height: 1.987rem;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 1.066rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.067rem;
        margin: 1.494rem 0 0 1.014rem;
    }


    .box_10s {
        background-color: rgba(0, 0, 0, 0.7);
        height: 58.88rem;
        width: 33.12rem;
        position: absolute;
        left: 0;
        top: 151px;
    }

    .group_17s {
        width: 33.12rem;
        height: 47.894rem;
        background: url(./assets/img/32c0dcb40dfbaf04497e2a9342045b5a.png) -0.08rem 0rem no-repeat;
        background-size: 33.2rem 47.894rem;
        margin-top: 10.987rem;
    }

    .text_29s {
        width: 17.494rem;
        height: 1.387rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.44rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 1.44rem;
        margin: 3.92rem 0 0 2.614rem;
    }

    .box_11s {
        width: 27.04rem;
        height: 5.174rem;
        margin: 3.574rem 0 0 3.04rem;
    }

    .text-wrapper_12s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        width: 5.174rem;
    }

    .text_30s {
        width: 0.427rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.374rem;
    }

    .text-wrapper_13s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_31s {
        width: 0.8rem;
        height: 1.174rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.187rem;
    }

    .text-wrapper_14s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.134rem;
        width: 5.174rem;
    }

    .text_32s {
        width: 0.8rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.187rem;
    }

    .text-wrapper_15s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_33s {
        width: 0.907rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.134rem;
    }

    .box_12s {
        width: 27.04rem;
        height: 5.174rem;
        margin: 2.427rem 0 0 3.04rem;
    }

    .text-wrapper_16s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        width: 5.174rem;
    }

    .text_34s {
        width: 0.8rem;
        height: 1.174rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.187rem;
    }

    .text-wrapper_17s {
        background-color: rgba(251, 116, 58, 1);
        height: 5.174rem;
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_35s {
        width: 0.854rem;
        height: 1.227rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.16rem;
    }

    .text-wrapper_18s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.134rem;
        width: 5.174rem;
    }

    .text_36s {
        width: 0.747rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 2.214rem;
    }

    .text-wrapper_19s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_37s {
        width: 0.854rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.16rem;
    }

    .box_13s {
        width: 27.04rem;
        height: 5.174rem;
        margin: 2.427rem 0 0 3.04rem;
    }

    .text-wrapper_20s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        width: 5.174rem;
    }

    .text_38s {
        width: 0.8rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 2.187rem;
    }

    .text-wrapper_21s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_39s {
        width: 1.68rem;
        height: 1.2rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 1.974rem 0 0 1.734rem;
    }

    .text-wrapper_22s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.134rem;
        width: 5.174rem;
    }

    .text_40s {
        width: 1.387rem;
        height: 1.147rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 1.894rem;
    }

    .text-wrapper_23s {
        background-color: rgba(255, 255, 255, 1);
        height: 5.174rem;
        border: 2px solid rgba(222, 222, 222, 1);
        margin-left: 2.107rem;
        width: 5.174rem;
    }

    .text_41s {
        width: 1.68rem;
        height: 1.174rem;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.6rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.6rem;
        margin: 2rem 0 0 1.76rem;
    }

    .text-wrapper_24s {
        background-color: rgba(255, 255, 255, 1);
        height: 4.534rem;
        /* border: 2px solid rgba(222, 222, 222, 1);*/
        width: 27.04rem;
        margin: 2.4rem 0 0 3.04rem;
    }

    .text_42s {
        /* width: 10.587rem;*/
        height: 5.254rem;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 1.333rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.334rem;
        /* margin: 1.627rem 0 0 1.654rem;*/
    }

    .text-wrapper_25s {
        background-color: rgba(251, 116, 58, 1);
        border-radius: 74px;
        height: 3.947rem;
        width: 27.2rem;
        margin: 3.92rem 0 3.84rem 2.88rem;
    }

    .text_43s {
        width: 2.667rem;
        height: 1.094rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.173rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        line-height: 1.174rem;
        margin: 1.44rem 0 0 12.294rem;
    }

    .van-hairline--bottom:after {
        border-bottom-width: 0;
    }
</style>